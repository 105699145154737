import React, { Component } from "react";

/* Components */
import Gimnasio from "../gimnasio";
import Instalaciones from "../instalaciones";
import Horarios from "../horarios";
import Prefooter from "../prefooter";

class Information extends Component {
  render() {
    return (
      <>
        <Gimnasio />
        <Instalaciones />
        <Horarios />
        <Prefooter />
      </>
    );
  }
}

export default Information;
