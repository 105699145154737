import React, { Component } from "react";
import Genealogia from "../modals/genealogia";
import DecretoPakHokPai from "../modals/decretoPakHojPai";

import MundoPak from "./MundoPak.jpg";
import Monje from "./Monje.jpg";

import LeyChit from "../modals/leyes/chit";
import LeyChun from "../modals/leyes/chun";
import LeyPsiem from "../modals/leyes/psiem";
import LeyTam from "../modals/leyes/tam";

import "./pakhokpai.css";

class PakHokPai extends Component {
  render() {
    return (
      <div className="services-block content content-center" id="PakHokPai">
        <div className="container">
          <h2>
            <strong>Pak Hok </strong>Pai
          </h2>
          <h4 className="text-justify">
            <p style={{ textindent: "5em" }}>
              Entre el conjunto de estilos que comprende el Wu Shu o Arte
              Marcial Nacional Chino, destaca por su elevada perfección, temible
              eficacia y belleza, el “Pak Hok Pai” o “Grulla Blanca”.
            </p>
            <p style={{ textindent: "5em" }}>
              Para el desarrollo de las artes marciales chinas y del Pak Hok Pai
              en particular, fueron decisivos los períodos comprendidos en las
              Dinastías MING (1368-1644) y CHING (1644-1911). En cuanto al Pak
              Hok Pai como sistema, su desarrollo se debe al genio y esfuerzo de
              dos grandes Maestros: At Tah Toh y To Lo Kat Tam.
            </p>
          </h4>

          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <img
                src={Monje}
                alt="Lama At Tah Toh"
                className="img-fluid center-block"
                width="152"
                height="118"
              />
              <p className="content-center">
                <strong>Lama At Tah Toh (Maestro Fundador).</strong>
              </p>
            </div>

            <div className="col-md-8 col-sm-8 col-xs-12">
              <h4 className="text-justify" style={{ textindent: "5em" }}>
                At Tah Toh estudió y se perfeccionó con los más avanzados monjes
                del Tíbet en artes marciales. Supo At Tah Toh elevar el arte
                marcial chino a un plano superior del hasta entonces conocido y
                dedujo cuatro leyes de defensa, seis materias y veinticuatro
                pensamientos. Tales logros alcanzados por At Tah Toh en las
                artes marciales tuvieron como base la observación de la
                naturaleza en general y de los fieros y mortales combates que
                los diversos animales libraban entre sí, siendo la grulla blanca
                el animal más importante por sus rápidos desplazamientos y
                eficacia defensiva que llamaron su atención.
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 col-sm-8 col-xs-12">
              <h4 className="text-justify">
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trescientos años
                  después, To Lo Kat Tam, otro genio, sintetizó y elevó aún más
                  el legado de At Tah Toh en el campo de las artes marciales
                  chinas y creó lo que hoy se conoce como Pak Hok Pai, gracias a
                  su elevada inteligencia, esfuerzo y dedicación sin par.
                </p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Por decisión de To
                  Lo Kat Tam, el Pak Hok Pai fue dado a conocer por seis de sus
                  Alumnos - Hijos más allá del Tibet, en toda la nación de
                  China. Sing Lum, uno de esos seis Alumnos - Hijos de To Lo Kat
                  Tam, fue el responsable de propagar el Sistema Pak Hok Pai en
                  la provincia de Cantón, en el sur de China.
                </p>
              </h4>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <img
                src={MundoPak}
                alt="Grulla"
                className="img-fluid center-block"
                width="152"
                height="118"
              />
              <p className="content-center">
                <strong>
                  La Grulla China representa inteligencia y longevidad(mas de
                  300 años)
                </strong>
              </p>
            </div>
          </div>

          <h3>
            {" "}
            En el Pak Hok Pai existen <strong>4 leyes</strong> que representan{" "}
          </h3>

          <div className="row">
            <LeyChit />
            <LeyChun />
            <LeyPsiem />
            <LeyTam />
          </div>

          <div className="row">
            <h4 className="text-justify">
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En Cantón, el Maestro
                Sing Lum tuvo cuatro Lamas Alumnos - Hijos. Además, dirigió los
                Monasterios Hing Wang y Pon Cheng, donde tuvo varios Alumnos –
                Hijos (civiles). En el Monasterio Hing Wang, uno de sus Alumnos
                - Hijos fue Chu Chi Yui; en el de Pon Cheng, su último Alumno -
                Hijo (civil) fue Wong Ling Hoi. Los Maestros Chu Chi Yui y Wong
                Ling Hoi le dieron origen a la rama de nuestra generación que se
                inicia con el Maestro Mng Psiu Chung, al que le sigue el Maestro
                Rector Kwong Poon Fu y le continúa el Maestro Chu Huen Tak.
              </p>
            </h4>
          </div>

          <Genealogia />
          <DecretoPakHokPai />
        </div>
      </div>
    );
  }
}
export default PakHokPai;
