import React from "react";

import escudoOro from "./CN_escudo.png";



function FichaCN(props) {

  var rows = [];
  for (var i = 0; i < props.shields; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    rows.push(
      <img src={escudoOro} alt="" className="img-fluid" width="45" height="45" />
    );
  }

  return (
    <>
      <div className="card mb-3 mr-3" style={{ maxWidth: "350px" }}>
        <div className="row no-gutters">
          <div className="col-md-4">
            <img
              src={props.picture}
              className="card-img mt-4"
              alt="..."
              width="76"
              height="108"
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">{props.name}</h5>
              {/*
                <p className="card-text">
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
                */}

              {rows}

              <p className="card-text">
                <small className="text-muted">Gradudo Año {props.year}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FichaCN;
