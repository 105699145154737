import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ChitImage from "./Chit.png";

import Modal from "react-bootstrap/Modal";
import "../modal.css";

function Chit() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="col-md-3 col-sm-3 col-xs-12 item leyesHover"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
      >
        <img
          src={ChitImage}
          width="110"
          height="100"
          className="leyesEscudoShape"
          alt=""
        />
        <h3>CHIT</h3>
        <p>Anticiparse</p>
      </div>

      <Modal show={show} onHide={handleClose} className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title>CHIT - Anticiparse</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <img src={ChitImage} alt="" className="img-fluid" />
          <p>
            &nbsp; &nbsp; Su significado literal puede ser{" "}
            <strong>"Anticiparse"</strong>. Cuando nosotros descubrimos la
            intención agresiva del enemigo nos anticipamos a su ataque
            poniendolo fuera de combate. Hay dos formas de Chit: <br /> a) Forma
            Severa. Se trata de golpear con gran violencia al enemigo un momento
            antes de producirse su ataque. <br /> b) Forma Suave. Cuando el
            enemigo ataca nosotros orientamos su ataque, desbalanceÃ¡ndolo,
            hasta que pierda el equilibrio y con ello el control del ataque; en
            ese momento nosotros aprovechamos su descontrol para ponerlo fuera
            de combate. Otro significado del vocablo Chit es "seguir la fuerza",
            así­ por ejemplo, suponiendo que el enemigo nos agarra por el brazo
            y nos hala por el, nosotros, en lugar de oponernos a su fuerza, la
            seguimos, atacandole. El Vocablo Chit tambien se orienta en el
            sentido de que cuando nosotros ataquemos lo debemos hacer siempre
            buscando la li­nea central del enemigo, de esta forma cubrimos
            nuestros puntos débiles.{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Chit;
