import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import TamImage from "./Tam.png";

import Modal from "react-bootstrap/Modal";
import "../modal.css";

function Tam() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="col-md-3 col-sm-3 col-xs-12 item leyesHover"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
      >
        <img  src={TamImage}
              width="110"
              height="100"
              className="leyesEscudoShape"
              alt=""
        />
        <h3>TAM</h3>
        <p>Malicia Decidida</p>
      </div>

      <Modal show={show} onHide={handleClose} className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title>TAM - Malicia Decidida</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <img src={TamImage} alt="" className="img-fluid" />
          <p>
            &nbsp; &nbsp; Su traducción literal podría ser{" "}
            <strong>"Malicia Decidida"</strong>. Significa que llegada la
            ocasión inevitable de pelear se hace de modo decidido, empleando a
            fondo todos los conocimientos, ya que si nosotros no golpeamos
            seremos golpeados. El hombre que al luchar vacila, pierde momentos
            precisos que pueden ser utilizados por el enemigo para liquidarlo o
            ponerlo fuera de combate; de modo que hay que luchar con toda la
            fuerza, astucia y rapidez que se sea capaz ante cualquier enemigo
            por insignificante que sea. El vocablo Tam también significa que al
            atacar hay que usar rápidos desplazamientos de un lado a otro para
            evitar que el enemigo precise nuestra posición y nos ataque; también
            quiere decir que un ataque sólo se deben golpear puntos débiles o
            mortales, y no perder energía golpeando a otros sitios.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Tam;
