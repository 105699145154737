import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ChunImage from "./Chun.png";

import Modal from "react-bootstrap/Modal";
import "../modal.css";

function Chun() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="col-md-3 col-sm-3 col-xs-12 item leyesHover"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
      >
        <img
          src={ChunImage}
          width="110"
          height="100"
          className="leyesEscudoShape"
          alt=""
        />
        <h3>CHUN</h3>
        <p>Traspasar</p>
      </div>

      <Modal show={show} onHide={handleClose} className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title>CHUN - Traspasar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <img src={ChunImage} alt="" className="img-fluid" />
          <p>
            &nbsp; &nbsp; Su significado literal es{" "}
            <strong>"traspasar".</strong> Todo plan de ataque, por perfecto que
            sea, tiene fallas; así­, al enfrentarse a un enemigo, debe
            mantenerse la serenidad y no pensar febrilmente en su acción
            ofensiva, ni tampoco menospreciarla, sino buscar los puntos débiles
            o "huecos" del ataque para "traspasar" por entre ellos y liquidar o
            poner fuera de combate al enemigo.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Chun;
