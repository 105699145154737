import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-scroll";

function LinkNavbar(props) {
  const isLinkContainer = props.linkConTo;
  if (isLinkContainer) {
    return (
      <>
        <LinkContainer to={"/" + props.linkConTo}>
          <Link
            activeClass="active"
            to={props.linkTo}
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="nav-link"
            style={{ cursor: "pointer" }}
          >
            {props.text}
          </Link>
        </LinkContainer>
      </>
    );
  } else {
    return (
      <>
        <Link
          activeClass="active"
          to={props.linkTo}
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="nav-link"
          style={{ cursor: "pointer" }}
        >
          {props.text}
        </Link>
      </>
    );
  }
}

export default LinkNavbar;
