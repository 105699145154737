import React, { Component } from "react";
import "./footer.css";
import "./socialIcons.css";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            {/* BEGIN COPYRIGHT */}
            <div className="col-md-6 col-sm-6">
              <div className="copyright">
                2020 © Escuela Superior de Kung Fu Gimnasio Tak. Todos Los
                derecho Reservados.
              </div>
            </div>
            {/*  END COPYRIGHT */}
            {/* BEGIN SOCIAL ICONS */}
            <div className="col-md-6 col-sm-6 pull-right">
              <ul className="social-icons">
                {/* <li>
                    <a className="rss" data-original-title="rss" href="javascript:void(0);"></a>
                </li> */}
                {/*   <li>
                    <a className="googleplus" data-original-title="googleplus" href="javascript:void(0);"></a>
                </li>
                <li>
                    <a className="linkedin" data-original-title="linkedin" href="javascript:void(0);"></a>
                </li> */}
                <li>
                  <a
                    className="youtube"
                    data-original-title="youtube"
                    href="https://www.youtube.com/watch?v=XDKysNg3bK0"
                  >&nbsp;</a>
                </li>

                <li>
                  <a
                    className="instagram"
                    href="https://instagram.com/gimnasiotak/"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>

                <li>
                  <a
                    className="twitter"
                    href="https://twitter.com/GimnasioTAK"
                  >&nbsp;</a>
                </li>

                <li>
                  <a
                    className="facebook"
                    data-original-title="facebook"
                    href="https://www.facebook.com/Gimnasio-Tak-1722990924466022/"
                  >&nbsp;</a>
                </li>
                {/*  <li>
                    <a className="vimeo" data-original-title="vimeo" href="javascript:void(0);"></a>
                </li>
                <li>
                    <a className="skype" data-original-title="skype" href="javascript:void(0);"></a>
                </li>  */}
              </ul>
            </div>
            {/* END SOCIAL ICONS */}
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
