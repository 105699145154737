import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import PsiemImage from "../Psiem.png";

import Modal from "react-bootstrap/Modal";
import "../modal.css";

function Psiem() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="col-md-3 col-sm-3 col-xs-12 item leyesHover"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
      >
        <img
          src={PsiemImage}
          width="110"
          height="100"
          className="leyesEscudoShape"
          alt=""
        />
        <h3>PSIEM</h3>
        <p>Esquivar o Desviar</p>
      </div>

      <Modal show={show} onHide={handleClose} className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title>PSIEM - Esquivar o Desviar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <img src={PsiemImage} alt="" className="img-fluid" />
          <p>
            &nbsp; &nbsp; Su traducción puede ser{" "}
            <strong>"Esquivar o Desviar"</strong>. Se puede esquivar avanzando o
            desplazando a los lados o hacia atrás, siempre eludiendo al enemigo
            pero sin huir, pues cuando nos ataquen con gran violencia nosotros
            debemos evitar el choque, esquivar y al mismo tiempo estar en
            posición de golpear los puntos débiles. El enemigo, debido a la
            misma violencia de su ataque, no tendrá ¡tiempo de retroceder para
            defenderse quedando liquidado. Cuando una persona bloquea,
            inmediatamente se ve obligada a bloquear otro y otro ataque, ya que
            el enemigo no se detiene, por tanto, al bloquear se pierde la
            iniciativa sobre el enemigo.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Psiem;
