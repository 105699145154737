import React, { Component } from "react";
import DecretoMaestroTak from "../modals/decretoMaestroTak";
import CredencialPresidenteVitalicio from "../modals/credencialPresidente";

import maestrokwonymaestrotak from "./maestrokwonymaestrotak.jpg";
import maestrotak from "./maestrotak.jpg";

import "./maestrotak.css";

class MaestroTak extends Component {
  render() {
    return (
      <div
      className="choose-us-block content text-center margin-bottom-40"
        id="MaestroTak"
      >
        <div className="container">
          <h2>
            EL MAESTRO (SIFÚ) <strong>CHU HUEN TAK</strong>
          </h2>
          <h4 className="text-justify">
            <p style={{ textIndent: "5em" }}>
              El Maestro Padre (Sifú) Chu Huen Tak, nació en el Sur de China, en
              el Estado de Cantón, en la región campestre de Chim Baá. Desde los
              tres años de edad, recibió lecciones del Arte Marcial Nacional
              Chino (Wu Shu) de varios conocedores del mismo con diversas
              jerarquías según su saber y dominio tanto en la región donde nació
              como, años después, en la capital de Cantón, en el tiempo en que
              cursaba educación media.
            </p>

            <p style={{ textIndent: "5em" }}>
              Por iniciativa propia, Chu Huen Tak siguió estudios de Wu Shu,
              Estilo del Norte. Viaja a Hong Kong a proseguir estudios
              superiores, avanza en el Wu Shu bajo la dirección de su Tío -
              Abuelo, quien dominaba varios estilos, entre ellos el Sistema Pak
              Hok Pai.
            </p>

            <p style={{ textIndent: "5em" }}>
              En 1957 Chu Huen Tak permanece por un corto tiempo en Caracas en
              compañía de su padre que administraba varios negocios de víveres.
            </p>
          </h4>

          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <img
                src={maestrokwonymaestrotak}
                alt=""
                className="img-fluid center-block"
                width="152"
                height="118"
              />
              <p className="content-center">
                <strong>Maestro Tak - Rector Kwong Poon Fu (Año 1974)</strong>
              </p>
            </div>
            <div className="col-md-8 col-sm-8 col-xs-12">
              <h4 className="text-justify" style={{ textIndent: "5em" }}>
                Regresa a China y su Tío-Abuelo lo presentó al sabio Sifú Kwong
                Poon Fu, Rector del Sistema Pak Hok Pai, y Buda de Oro, quien lo
                acepta como Alumno - Hijo por su dedicación, excelente capacidad
                y cualidades de todo tipo. Bajo la dirección del Rector Kwong,
                el Sifú Chu Huen Tak obtuvo el Noveno Escudo o Dragón de Oro,
                máximo grado, así como variados conocimientos de medicina.
              </h4>
            </div>
          </div>

          <h4 className="text-justify">
            <p style={{ textIndent: "5em" }}>
              El Sifú Chu Huen Tak regresa a Venezuela en 1965 y funda la actual
              Escuela Superior de Kung Fu (Wu Shu), Sistema Pak Hok Pai, en la
              ciudad de Caracas. Es la primera y más importante.
            </p>
          </h4>

          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <img
                src={maestrotak}
                alt=""
                className="img-fluid center-block"
                width="152"
                height="118"
              />
              <p className="content-center">
                <strong>Maestro Chu Huen Tak (Año 2008)</strong>
              </p>
            </div>
            <div className="col-md-8 col-sm-8 col-xs-12">
              <h4 className="text-justify" style={{ textIndent: "5em" }}>
                Por su espíritu de superación el Sifú Tak viajó varias veces a
                China para seguir avanzando en sus ya elevados conocimientos. En
                1974 le otorgan el Título - Credencial de Presidente Vitalicio
                de la Federación Atlética de Pak Hok Pai en el exterior de
                China. En agosto del 2001 fué nombrado alumno-sucesor legítimo
                por el venerable Rector Kwong Poon Fu. Años después, en el 2005,
                recibió del Rector del Sistema Pak Hok Pai, Sifú Kwong Poon Fu,
                el Decreto - Credencial que lo acredita como sucesor legítimo
                del rectorado del Sistema Pak Hok Pai para la región
                Latinoamericana (Centro y Sur América).
              </h4>
            </div>
          </div>

          {/*<!--<h4 className="text-justify">
                <p style="text-indent: 5em">
                    Las personas que conocieron al Sifú Chu Huen Tak fueron honradas con todas sus enseñanzas que
                    supo llevar a nosotros a través del bello Arte del Pak Hok Pai, en vida fue un verdadero pedagogo,
                    pues su alma y sus ojos siempre estaban atentos a las particularidades individuales de sus discípulos y cuando era necesario,
                    un consejo u orientación acerca de la vida, siempre estuvo como un Padre.
                    Su actitud y comportamiento nos remiten a valores como la honradez, la amistad, el trabajo, el valor, la tolerancia,
                    amor a la naturaleza y a la patria.
                </p>
                <p style="text-indent: 5em">
                    El maestro partió de nuestro lado el 12 de Noviembre del Año 2012 dejando un Gran legado que continua a través
                    de todos sus discípulos y sus enseñanzas siempre recordado con gran amor y
                    pasión por todos los que tubieran la dicha de conocerlo.
                </p>
            </h4> --> */}
          <br />
          <DecretoMaestroTak />
          <CredencialPresidenteVitalicio />
        </div>
      </div>
    );
  }
}
export default MaestroTak;
