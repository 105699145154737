import React, { Component } from "react";
import InstalacionesGaleria from "./instalacionesgaleria";

import "./instalaciones.css";
import "./Prof_alumnos.jpg";

const gallery = [
  {
    small: "Images/Instalaciones/GimnasioA.jpg",
    large: "Images/Instalaciones/GimnasioA.jpg",
  },
  {
    small: "Images/Instalaciones/Equipo.jpg",
    large: "Images/Instalaciones/Equipo.jpg",
  },
  {
    small: "Images/Instalaciones/techado%20(Small).jpg",
    large: "Images/Instalaciones/techado%20(Small).jpg",
  },
  {
    small: "Images/Instalaciones/Armeria.jpg",
    large: "Images/Instalaciones/Armeria.jpg",
  },
  {
    small: "Images/Instalaciones/AlumnosEx.jpg",
    large: "Images/Instalaciones/AlumnosEx.jpg",
  },
  {
    small: "Images/Instalaciones/GimnasioTechado.jpg",
    large: "Images/Instalaciones/GimnasioTechado.jpg",
  },
  {
    small: "Images/Instalaciones/Multifuerzas3.jpg",
    large: "Images/Instalaciones/Multifuerzas3.jpg",
  },
  {
    small: "Images/Instalaciones/SalaReunion.jpg",
    large: "Images/Instalaciones/SalaReunion.jpg",
  },
  {
    small: "Images/Instalaciones/AreaVisitantes.jpg",
    large: "Images/Instalaciones/AreaVisitantes.jpg",
  },
  {
    small: "Images/Instalaciones/AreaRelacion.jpg",
    large: "Images/Instalaciones/AreaRelacion.jpg",
  },
  {
    small: "Images/Instalaciones/AreaRelajacion2.jpg",
    large: "Images/Instalaciones/AreaRelajacion2.jpg",
  },
];

class Instalaciones extends Component {
  render() {
    return (
      <div>
        <div
          className="message-block content content-center"
          id="Instalaciones"
        >
          <div className="InstalationTextCustom">GIMNASIO TAK</div>
        </div>

        <div
          className="portfolio-block content content-center"
          id="Instalaciones2"
        >
          <div className="row">
            {gallery.map((ga) => (
              <InstalacionesGaleria
                key={ga.small}
                small={ga.small}
                large={ga.large}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default Instalaciones;
