import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import botoncinturones from "./botonCinturones.png";
import decretoMaestroTak from "./Credenciales/DecretoCredencialPakHokPai  Maestro Tak50 dpia.jpg";

import maestrosPakHokPai from "./Credenciales/familia.jpg";
import numeroUno from "./Credenciales/numeros 1 copia.png";
import numeroDos from "./Credenciales/numero2 copia.png";
import numeroTres from "./Credenciales/numero 3 copia.png";

function DecretoMaestroTak() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="row vertical-align">
        <div className="col-md-2 col-sm-2 col-xs-12">
          <img
            src={botoncinturones}
            alt=""
            className="img-fluid center-block"
            data-toggle="modal"
            data-target="#genealogia"
            width="58"
            height="45"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          />
        </div>
        <div className="col-md-8 col-sm-8 col-xs-12 text-left">
          <div className="content-left" style={{ fontSize: "150%" }}>
            <strong>Decreto-Credencial Sucesoral Maestro Rector</strong>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Decreto-Credencial Sucesoral Maestro Rector</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <img src={decretoMaestroTak} className="img-fluid center-block" alt=""/>
          <br />
          <br />
          <p>
            <img src={numeroUno} width="20px" height="30px" alt=""/>
            Chun Tip o Decreto – Credencial único para designar el sucesor a
            Rector de Pak Hok Pai.
          </p>
          <p>
            Yo, Maestro Rector Psiu Chung estudie el Arte Marcial y medicina Pak
            Hok Pai con los Maestros Wong Lin Hoy y Chu Chi Yiu, quienes
            estudiaron en el Monasterio Neblina Blanca (Pak Wuen Chi) con el
            Maestro-Rector Lama Sing Lum, Fundador de la Hermandad Universitaria
            Pak Hok Pai en el sur de China, Estado Cantón, Provincia Sieo Hing,
            Región del Lago Montañoso de Tin Wu.
          </p>
          <p>
            En cumplimiento del Decreto Sucesoral de los Maestros Fundadores,
            Lama Ah Tah Toh y Lama To Lo Kat Tam, relativo a desarrollar el Arte
            marcial y educar el Arte a todo el pueblo del territorio de China.
            Yo recibí el Rectorado de las manos de Wong Lin Hoy y de Chu Chi
            Yiu. Ahora se lo otorgo a mi discípulo -hijo Kwong Poon Fu, quien
            estudia conmigo desde hace más de diez años sin faltar un día. Igual
            que la Hormiga construye su nido, ha alcanzado logros a través de su
            constancia, dedicación, inteligencia y amor. Ha cumplido los
            requisitos exigidos por el Pak Hok Pai y, por su alta moral, es una
            alegría entregar el rectorado a mi mejor discípulo - hijo Kwong Poon
            Fu, quien debe continuar el pensamiento de nuestros antepasados
            Maestros: desarrollar el Arte Marcial y educar a las futuras
            generaciones.
          </p>
          <p>
            <img src={numeroDos} width="20px" height="30px" alt=""/>
            (Otorgado por el Maestro-Rector 3ª generación Pak Hok Pai, Mng Psiu
            Chung. Firma y Sello. Año de 1937, mes de Julio del Día 24, Hong
            Kong).
          </p>

          <p>
            <img src={numeroTres} width="20px" height="30px" alt=""/> Decreto
            Credencial de Rector
          </p>
          <p>
            Cumpliendo todos los requisitos exigidos del Arte Marcial Pak Hok
            Pai,otorgo el rectorado a mi Discípulo - Hijo, heredero legítino,
            Chu Huen Tak por su excelente capacidad, quien será responsable de
            continuar y transmitir todo el conocimiento original a la futura
            generación. (Chu Huen Tak o "Win Ko"). nombre que le corresponde de
            acuerdo a su generación y jerarquía en el Sistema Pak Hok Pai)
            (Firma y Sello del Maestro Rector Kwong Poon Fuk, Pak Hok Pai 4ta
            generación). Año 2005, mes de Diciembre del Día 22, Vancouver,
            Canada.
          </p>
          <img src={maestrosPakHokPai} className="img-fluid" alt=""/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DecretoMaestroTak;
