import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import botoncinturones from "./botonCinturones.png";
import presidenteVitalicio from "./Credenciales/PRESIDVITALICIODIPLOM50 dpi copia.jpg";
import credencialEmbajada from "./Credenciales/este.jpg";
import federacionPakHokPai from "./Credenciales/1976.jpg";

function CredencialPresidenteVitalicio() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="row vertical-align">
        <div className="col-md-2 col-sm-2 col-xs-12">
          <img
            src={botoncinturones}
            alt=""
            className="img-fluid center-block"
            data-toggle="modal"
            data-target="#genealogia"
            width="58"
            height="45"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          />
        </div>
        <div className="col-md-8 col-sm-8 col-xs-12 text-left">
          <div className="content-left" style={{ fontSize: "150%" }}>
            <strong>Credencial de Presidente Vitalicio</strong>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Credencial de Presidente Vitalicio</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <img
            src={presidenteVitalicio}
            alt=""
            className="img-fluid center-block"
          />
          <br />
          <p>
            Credencial de la Federación Atlética de Pak Hok Pai otorgado al
            Maestro Chu Huen Tak que lo designa como Presidente Vitalicio.
          </p>
          <img src={credencialEmbajada} alt="" className="img-fluid center-block" />
          <br />
          <p>
            Traducción al Castellano de la Credencial Otorgada al Maestro Chu
            Huen Tak.
          </p>
          <img
            src={federacionPakHokPai}
            alt=""
            className="img-fluid center-block"
          />
          <br />
          <p>
            Maestro Chu Huen Tak con toda la Directiva de la Federación Atlética
            de Pak Hok Pai (PaK Hok Athletic Federation Limited) y después de
            haber recibido la Credencial de Presidente Vitalicio, ano 1974.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          {/*   <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
          */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CredencialPresidenteVitalicio;
