import React, { Component } from "react";
import Slider from "react-animated-slider";

import "react-animated-slider/build/horizontal.css";
import "./promo.css";

import Prof_alumnosLow from "./Prof_alumnosLow.jpg";
import Escudo_L from "./Escudo_L.png";

const slides = [
  {
    title: "Escuela Superior de Kung Fu Gimnasio Tak",
    description:
      "Somos la Primera Escuela de Kung Fu en Venezuela Fundada en 1965 por el Maestro Chu Huen Tak",
    image: Prof_alumnosLow,
    size: "cover",
  },
  {
    image: Escudo_L,
    size: "auto",
  },
];

class Promo extends Component {
  render() {
    return (
      <div
        className="promo-block"
        id="promo-block"
        style={{ minHeight: "100vh" }}
      >
        <Slider autoplay={5500}>
          {slides.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${item.image}') no-repeat center center `,
                backgroundSize: item.size,
              }}
            >
              <div className="inner">
                <h1>{item.title}</h1>
                <p>{item.description}</p>
                {/* <button>{item.button}</button> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
export default Promo;
