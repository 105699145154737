import React, { Component } from "react";
import "./horarios.css";

class HorariosBox extends Component {
  render() {
    return (
      <>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="pricing-item">
            <div className="pricing-head">
              <strong style={{ color: "red" }}>{this.props.title}</strong>
              <p>{this.props.days}</p>
            </div>
            <div className="pricing-content">
              <h2 style={{ borderBottom: "none" }}>{this.props.from}</h2> A{" "}
              <h2>{this.props.to}</h2>
              <br />
            </div>
            <div className="pricing-footer">{this.props.target}</div>
          </div>
        </div>
      </>
    );
  }
}

export default HorariosBox;
