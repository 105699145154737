import React, { Component } from "react";
import "./testimonios.css";

class Testimonios extends Component {
  render() {
    return (
      <div className="testimonials-block content content-center margin-bottom-65">
        <div className="container">
          <div className="carousel-inner">
            <div className="active item">
              <blockquote>
                <p>
                  Cuando se es joven, se debe hacer el máximo esfuerzo de
                  superación en el plano mental y fisico para así llegar a una
                  vejez saludable tranquila y en armonía. Esto se puede lograr a
                  travéz de la voluntad y el amor por lo que hacemos.
                </p>
              </blockquote>
              <br />
              <span
                className="testimonials-name"
                style={{ color: "black", fontSize: "20px" }}
              >
                MAESTRO CHU HUEN TAK
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Testimonios;
