import React, { Component } from "react";

import TemploPan from "../kungfu/TemploPan.png";
import TemploMon from "../kungfu/TemploMon.png";
import "../kungfu/kungfu.css";

class Kungfu extends Component {
  render() {
    return (
      <div className="about-block content content-center" id="kungfu">
        <div className="container">
          <h2>
            <strong>Kung Fu</strong> Wu Shu
          </h2>
          <h4 className="text-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Por circunstancias no
            atribuibles al pueblo chino, en el mundo occidental se ha
            generalizado la incorrecta interpretación del término “Kung Fu”.
            Así, tanto en la cultura anglosajona como europea, a la expresión
            Kung Fu se le ha dado el significado de Arte Marcial Chino, de
            sistema relativo al ataque y defensa con mano vacía o con armas de
            diverso tipo. Nada más distante del verdadero contenido de esa
            expresión. Con propiedad, el término Kung Fu, originalmente, nos
            remite a la idea de constancia, habilidad y dedicación. O sea que
            cualquier ejercicio físico y mental que exija de la constancia,
            habilidad, dedicación y, mediante tales virtudes, se alcance una
            perfecta y elevada ejecución, se denomina Kung Fu. Dominar un oficio
            cualquiera mediante tales recursos es poseer un reconocible Kung Fu.
          </h4>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <h4 className="text-justify">
                A la generalización de la incorrecta interpretación del término
                Kung Fu han contribuido el cine y las series televisivas. Al día
                de hoy, tal vez sea la expresión china más conocida de modo
                erróneo en el mundo occidental. Si queremos hacer referencia al
                “Arte Marcial Nacional Chino” debemos alcanzar la eficacia y
                belleza que hoy exhibe. A ello ha contribuido el establecimiento
                de una Universidad de Arte de Defensa China en la época de la
                República que clasificó más de 300 estilos tenidos como
                superiores, lo que ha facilitado su enseñanza del modo más
                adecuado. Ahora bien, sólo algunos entre esos 300 estilos del Wu
                Shu alcanzan la categoría “Pai” o nivel universitario de máxima
                perfección.
              </h4>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <img
                src={TemploPan}
                alt="Why to choose us"
                className="img-fluid center-block"
              />
              <p>
                <strong>
                  Monasterio Sun Sin. Estado Neblina del Sur(Wun Nam), China.
                  (Año 2007).
                </strong>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <img
                src={TemploMon}
                alt="Why to choose us"
                className="img-fluid center-block"
              />
              <p>
                <strong>
                  Monasterio Sun Sin. Facultad de Medicina Estado Neblina del
                  Sur(Wun Nam), China. (Año 2007).
                </strong>
              </p>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <h4 className="text-justify">
                El Arte Marcial Nacional Chino o Wu Shu es el resultado de una
                prolongada y consciente elaboración en el tiempo de varias
                generaciones dedicadas a su desarrollo y perfeccionamiento. Sus
                orígenes más remotos nos remiten aproximadamente al año 5.000
                antes de Cristo, según documentos. A lo largo de los siglos y en
                diversas dinastías, el Wu Shu se fue perfeccionando hasta
                emplear el término “Wu Shu”y no el de Kung Fu. El Vocablo Wu Shu
                sí alude al complejo, eficaz y sofisticado sistema marcial
                chino. Wu Shu, además, refiere a la belleza artística en la
                ejecución del arte marcial con la mano vacía o con cualquier
                arma.
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Kungfu;