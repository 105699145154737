import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import browserHistory from "react-dom";
/*import { Redirect } from "react-router-dom";*/

/* Components */
import Navbar from "../navbar";
import Promo from "../promo";
import Institution from "../institution";
import Information from "../information";
import Footer from "../footer";
import ScrollTopArrow from "./ScrollTopArrow";
import WhatsApp from "./whatsapp";

/* Protection Level */
//import Login from "../login";

/* Bootstrap */
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

/* Styles */
import "../main-page/app.css";
import "../main-page/SiteCustom.css";

const supportsHistory = "pushState" in window.history;

function App() {
  /*
  if (!sessionStorage.getItem("auth-token")) {
    console.log("no auth token set 1");
    //do something like redirect to login page
    return (
      <Router>
        <Redirect
          to={{
            pathname: "/Institution",
            search: "?utm=your+face"
          }}
        />
      </Router>
    );
  } else {
    const authToken = "123456abcdef";
    if (sessionStorage.getItem("auth-token") === authToken) {
      console.log("good token. Log in. 2");
      //do something like redirect to todo page
    } else {
      console.log("bad token. 3");
      //do something like redirect to login page
    }
  };
  */

  /*
  if (!sessionStorage.getItem("auth-token")) {
    console.log("no auth token set 1");
    //do something like redirect to login page
    return (
      <>
        <Router basename="." forceRefresh={!supportsHistory}>
          <Login />
        </Router>
      </>
    );
  } else {
    const authToken = "123456abcdef";
    if (sessionStorage.getItem("auth-token") === authToken) {
      console.log("good token. Log in. 2");
      //do something like redirect to todo page
    } else {
      console.log("bad token. 3");
      //do something like redirect to login page
    }
  }
*/
  return (
    <>
      <Router basename="." forceRefresh={!supportsHistory}>
        <Promo />
        <Navbar module="information" />
        <Switch>
          <Route
            exact
            history={browserHistory}
            path="/Institution"
            component={Institution}
          />
          <Route
            exact
            history={browserHistory}
            path="/Inicio"
            component={Information}
          />
          <Route
            exact
            history={browserHistory}
            path="/"
            component={Information}
          />
          <Route
            exact
            history={browserHistory}
            path="*"
            component={Information}
          />
        </Switch>
        <Footer />
        <ScrollTopArrow />
      </Router>
      <WhatsApp />
    </>
  );
}

export default App;
