import React, { Component } from "react";
import HorarioBox from "./horarioBox";
import "./horarios.css";

class Horarios extends Component {
  render() {
    return (
      <div>
        <div className="prices-init content content-center" id="Horarios">
          <div className="container">
            <h2>
              <strong>Horarios de Clases Matutina</strong>
            </h2>
            <div className="row justify-content-center">
              <HorarioBox
                title="Kung Fu"
                days="Lunes - Miercoles - Viernes"
                from="8:00 am"
                to="9:00 am"
                target="Dirigido a Jovenes y Adultos"
              />
            </div>
          </div>
        </div>

        <div className="prices-block content content-center" id="Horarios">
          <div className="container">
            <h2>
              <strong>Horarios de Clases Vespertina</strong>
            </h2>
            <div className="row justify-content-center">
              <HorarioBox
                title="Kung Fu"
                days="Lunes - Miercoles - Viernes"
                from="4:00 pm"
                to="5:00 pm"
                target="Dirigido a Jovenes y Adultos"
              />

              <HorarioBox
                title="Kung Fu"
                days="Lunes - Miercoles - Viernes"
                from="5:10 pm"
                to="6:10 pm"
                target="Dirigido a Niños Jovenes y Adultos"
              />

              <HorarioBox
                title="Kung Fu"
                days="Lunes - Miercoles - Viernes"
                from="6:15 pm"
                t
                to="7:15 pm"
                target="Dirigido a Jovenes y Adultos"
              />

              <HorarioBox
                title="Kung Fu"
                days="Lunes - Miercoles - Viernes"
                from="7:15 pm"
                to="8:15 pm"
                target="Dirigido a Jovenes y Adultos"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Horarios;
