import React, { Component } from "react";
import ModalImage from "react-modal-image";

class InstalacionesGaleria extends Component {
  render() {
    return (
      <div className="item col-md-2 col-sm-6 col-xs-12">
        <ModalImage
          small={this.props.small}
          large={this.props.large}
          alt=""
          className="modal-image"
        />
        <div className="valign-center-elem">
          <strong>Gimnasio al Aire Libre</strong>
          <br />
          <b>Ver Foto</b>
        </div>
      </div>
    );
  }
}
export default InstalacionesGaleria;
