import React, { Component } from "react";
import InstagramEmbed from "react-instagram-embed";
import "./preefooter.css";

/*
const containerStyle = {
  width: "400px",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};
*/

class Prefooter extends Component {
  render() {
    return (
      <div className="pre-footer" id="Contactenos">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 pre-footer-col">
              <h2>Dirección y Telefonos</h2>
              <address className="margin-bottom-20">
                EL PARAISO, URB. LAS FUENTES, <br />
                2da AV. LAS FUENTES, QUINTA <br />
                TRINA. CARACAS, REPUBLICA <br />
                BOLIVARIANA DE VENEZUELA <br />
                <br />
                Email:{" "}
                <a href="mailto:pagos.gimnasiotak@gmail.com">
                  pagos.gimnasiotak@gmail.com
                </a>
                <br />
                <br />
                Telefono: (0212) 461-86-47 <br />
                WhatsApp:{" "}
                <a
                  href="https://api.whatsapp.com/send?phone=+5804123923057&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20*El%20Gimnasio%20Tak*%20"

                  /* target="_blank" */
                >
                  04123923057
                </a>
              </address>
            </div>

            <div className="col-md-4 col-sm-6 pre-footer-col">
              <h2>Mapa</h2>

              <div id="map">
                <iframe
                  title="Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1649.4891879318047!2d-66.94287222070444!3d10.487375289121031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa18ca0065877edbd!2sGimnasio%20Tak!5e0!3m2!1ses-419!2sve!4v1597718380965!5m2!1ses-419!2sve"
                  width="100%"
                  height="486px"
                  frameBorder="0"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 pre-footer-col">
              <h2>Instagram</h2>
              <InstagramEmbed
                url="https://instagram.com/p/B4w27vDhlo7/"
                clientAccessToken="724815248095194|00b322a481e0d9b3c60245d3d48a8ea6"
                maxWidth={200}
                hideCaption={false}
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Prefooter;
