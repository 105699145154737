import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";

/* Component */
import LinkNavbar from "./LinkNavbar";
import "./header.css";

  const informationRender = () => 
  <>
    <LinkNavbar linkConTo="Inicio" text="Inicio" linkTo="promo-block" />
    <LinkNavbar text="El Gimnasio" linkTo="Gimnasio" />
    <LinkNavbar text="Instalaciones" linkTo="Instalaciones" />
    <LinkNavbar text="Horarios" linkTo="Horarios" />
    <LinkNavbar text="Contactenos" linkTo="Contactenos" />
  </>;

  const institutionRender = () => 
  <>
    <LinkNavbar linkConTo="Institution" text="Institución" linkTo="promo-block" />
    <LinkNavbar linkConTo="Inicio" text="Inicio" linkTo="promo-block" />
    <LinkNavbar text="Kung Fu/Wu Shu" linkTo="kungfu" />
    <LinkNavbar text="Pak Hok Pai" linkTo="PakHokPai" />
    <LinkNavbar text="Maestro Tak" linkTo="MaestroTak" />
  </>;

class Header extends Component {
  render() {
    const selector = this.props.module;
    var menuToShow = null;
    if(selector === "information") {
       menuToShow  = informationRender();
    }
    if(selector === "institution") {
       menuToShow  = institutionRender();
    }

    return (
      <Navbar bg="light" expand="lg" sticky="top" className="header">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="respnsive-navbar-nav"
          className="justify-content"
        >
         {menuToShow}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;