import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import genealogia from "./Credenciales/genealogia.png";
import botoncinturones from "./botonCinturones.png";

function Genalogia() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="row vertical-align">
        <div className="col-md-2 col-sm-2 col-xs-12">
          <img
            src={botoncinturones}
            alt=""
            className="img-fluid center-block"
            data-toggle="modal"
            data-target="#genealogia"
            width="58"
            height="45"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          />
        </div>
        <div className="col-md-8 col-sm-8 col-xs-12 text-left">
          <div className="content-left" style={{ fontSize: "150%" }}>
            <strong>Genealogía</strong>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Genealogia.</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">
          <img src={genealogia} alt="" className="img-fluid center-block" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          {/*   <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
          */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Genalogia;
