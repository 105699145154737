import React, { Component } from "react";

import Escuela from "./Escuela.jpg";
import Escuela2 from "./Escuela2.jpg";

import "./gimnasio.css";

class Gimnasio extends Component {
  render() {
    return (
      <div className="services-block content content-center" id="Gimnasio">
        <div className="container">
          <h2>
            Gimnasio Tak <strong>Nuestra Escuela</strong>
          </h2>
          <h4 className="text-justify" style={{ textIndent: "5em" }}>
            La actual sede de la Escuela Superior de Kung Fu, Wu Shu, Sistema
            Pak Hok Pai, ubicada en Caracas, Urbanización El Paraíso, fue
            fundada por el Maestro Tak en 1965. No obstante, el Maestro Tak
            comenzó a enseñar el Wu Shu algún tiempo antes en la urbanización
            caraqueña de Vista Alegre. Con las enseñanzas del Maestro Tak llega
            a Venezuela, por vez primera, el Wu Shu, Sistema Pak Hok Pai; un
            conocimiento de Arte Marcial Chino tan eficiente como bello y que,
            hasta ese momento no se conocía entre nosotros. Un conocimiento de
            Arte Marcial impartido por un virtuoso que en cada enseñanza muestra
            el poder y belleza del Wu Shu.
          </h4>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <img
                src={Escuela}
                alt=""
                className="img-fluid center-block"
                width="300"
                height="118"
              />
              <p className="content-center">
                <strong>Grupo de Alumnos Gimnasio Tak</strong>
              </p>
            </div>
            <div className="col-md-8 col-sm-8 col-xs-12 text-left">
              <h4 className="text-justify" style={{ textIndent: "5em" }}>
                <p>
                  La enseñanza en la Escuela Superior de Kung Fu (Wu Shu),
                  Sistema Pak Hok Pai, está organizada en tres niveles de
                  exigencia y complejidad ascendente. El primer nivel es el Bajo
                  o Preparatorio, comparativamente, equivale al 6º grado de
                  nuestro sistema escolar formal; el nivel Medio, equivale al
                  bachillerato; y, el nivel Superior, al universitario.
                </p>
                <br />
                <p>
                  <b>El Nivel Basico o Preparatorio:</b> en este nivel los
                  movimientos defensivos y ofensivos se realizan en dos tiempos,
                  o sea que primero se bloquea el ataque del agresor y luego se
                  pasa al contra - ataque.
                </p>
                <p>
                  <b> El Nivel Medio:</b> también aquí los movimientos se
                  realizan en dos tiempos, pero no mediante bloqueos sino
                  desviando el ataque del agresor; una vez realizado el desvío
                  del ataque, se contra-ataca con la misma fuerza con la que se
                  defendió el movimiento ofensivo del agresor.
                </p>
                <p>
                  <b> El Nivel Superior:</b> a diferencia de los anteriores, el
                  movimiento ofensivo del agresor se usa como medio de defensa
                  contra su ataque; o sea, se esquiva y se ataca
                  simultáneamente, sin huir.
                </p>
              </h4>
            </div>
          </div>
          <br />
          <h4 className="text-justify" style={{ textIndent: "5em" }}>
            En síntesis, la escuela Superior de Kung Fu, (Wu Shu), Sistema Pak
            Hok Pai, reúne todos los niveles, desde el nivel Bajo o Preparatorio
            hasta la Maestría. De modo general, los estudios de pregrado o
            previos al Nivel Superior se dividen en cinco niveles del modo
            siguiente: desde el primero hasta el tercer kip, equivale al
            bachillerato; desde el cuarto al quinto, corresponde al período de
            preparación de la licenciatura en arte marcial; finalmente, vienen
            los niveles de postgrado para llegar a la Maestría, la cual va desde
            el primer Escudo de Dragón hasta el noveno.
          </h4>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <img
                src={Escuela2}
                alt=""
                className="img-fluid center-block"
                width="300"
                height="118"
              />
              <p className="content-center">
                <strong>
                  Alumnos Gimnasio Tak (Ejecución Patada Voladora)
                </strong>
              </p>
            </div>
            <div className="col-md-8 col-sm-8 col-xs-12 text-left">
              <h4 className="text-justify" style={{ textIndent: "5em" }}>
                Más allá del aprendizaje de un muy eficiente sistema defensivo –
                ofensivo de Arte Marcial Chino, de todos es sabido que la
                práctica del Kung Fu (Wu Shu), Sistema Pak Hok Pai, redunda en
                beneficios para la salud física y mental. En cuanto al nivel
                físico, su práctica continua aumenta las valencias en aspectos
                como la respiración aeróbica y anaeróbica, la fuerza, velocidad,
                coordinación, flexibilidad, agilidad, rapidez y precisión. En el
                aspecto mental, se experimenta de modo creciente la seguridad y
                sensación de tranquilidad interior. El Kung Fu (Wu Shu), Sistema
                Pak Hok Pai, está considerado mundialmente como uno de los más
                completos que existen en las artes marciales.
              </h4>
            </div>
          </div>
          <br />
          <h4 className="text-justify" style={{ textIndent: "5em" }}>
            Su eficiencia agresiva ante un ataque lo coloca como un sistema
            defensivo - ofensivo no apto para competencias deportivas. Un
            verdadero estudiante de Wu Shu, sistema Pak Hok Pai, sabe que debe
            perfeccionar cada ataque con un efecto mortal, pues la defensa de la
            propia vida depende de fracciones de segundo; en consecuencia, un
            individuo culto e inteligente no debe hacer daño a otro por un
            simple trofeo o una medalla que se dispute en una competencia.
          </h4>
        </div>
      </div>
    );
  }
}
export default Gimnasio;
