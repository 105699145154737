import React, { Component } from "react";
import "../cinturonesnegros/cinturonesNegros.css";

/* Components */
import FichaCN from "../cinturonesnegros/fichaCN.js";
var data = [];

class Institution extends Component {
  componentDidMount() {
    // GET request using fetch with error handling
    fetch("/listaCN.json")
      .then(async (response) => {
        data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        this.setState({ totalReactPackages: data.total });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  }

  render() {
    return (
      <>
        <div className="cinturonesNegros-block content" id="cinturonesNegros">
          <div className="container">
            <h2>
              <strong>Docentes</strong> Sede Central
            </h2>
            <div className="row no-gutters">

            {data.map((CN) => (
            <FichaCN  picture={CN.picture} name={CN.name} year={CN.year} shields={CN.shields}/>
            ))}


            </div>
            <h2>
              <strong>Vitalicios</strong>
            </h2>
          </div>
        </div>
      </>
    );
  }
}

export default Institution;
