import React from "react";

/* Components */
import KungFu from "../kungfu";
import PakHokPai from "../pakhokpai";
import MaestroTak from "../maestrotak";
import Testimonios from "../testimonios";
import CinturonesNegros from "../cinturonesnegros";

function Institution() {
  return (
    <>
      <KungFu />
      <PakHokPai />
      <MaestroTak />
      <Testimonios />
      <CinturonesNegros />
    </>
  );
}

export default Institution;
